/**
 * mediaquery
 */
mq(point)
  bd = mq_config.bd
  bp_01 = mq_config.bp_01
  bp_02 = mq_config.bp_02

  if (bd == "sp")
    if (point == "tab")
      @media screen and (min-width bp_01)
        {block}

    else if (point == "pc")
      @media screen and (min-width bp_02)
        {block}

    else
      background-color red

  else if (bd == "pc")
    if (point == "tab")
      @media screen and (max-width (bp_02 - 1))
        {block}

    else if (point == "sp")
      @media screen and (max-width (bp_01 - 1))
        {block}

    else
      background-color red

  else
    background-color red
