/**
 * clearfix
 */
cf()
  &::after
    content ""
    display block
    clear both

/**
 * flexbox
 */
flex(layout)
  display flex

  if (layout == "ver")
    flex-direction column
    justify-content space-between

  else if (layout == "hor")
    justify-content space-between
    flex-wrap wrap

  else
    background-color red

/**
 * position
 */
pos(layout)
  position absolute

  if (layout == "left")
    top 50%
    left 0
    transform translateY(-50%)

  else if (layout == "right")
    top 50%
    right 0
    transform translateY(-50%)

  else if (layout == "center")
    top 50%
    left 50%
    transform translate(-50%, -50%)

  else if (layout == "top")
    top 0
    left 50%
    transform translateX(-50%)

  else if (layout == "bottom")
    bottom 0
    left 50%
    transform translateX(-50%)

  else
    background-color red

/**
 * image
 */
bg(path, bg = true)
  path = "../img/" + path
  background-image url(path)
  background-position center center
  background-repeat no-repeat

  if (bg == true) {
    background-size cover
  }

  else if (bg == false) {
    background-size contain
  }

  else {
    background-color red
  }

setBg(path)
  path = "../img/" + path
  background-image url(path)

removeBg()
  background-image url("")

zoomBg(path)
  position relative
  overflow hidden

  &::after
    content ""
    width 100%
    height 100%
    bg(path)
    pos(center)
    transition all .3s

  &:hover
    &::after
      transform translate(-50%, -50%) scale(1.2)

zoomImg()
  overflow hidden

  img
    width 100%
    transition all .3s

  &:hover
    img
      transform scale(1.2)
