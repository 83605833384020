.access
  height 425px

  +mq(sp)
    height 240px
// --access

footer
  background-color violet_01
  color white_01
  padding px--lg(160) 0 px--lg(25)

  +mq(sp)
    padding 60px 0 15px

  .wrapper
    max-width px--lg(1660)
    flex(hor)
    align-items center
    margin 0 auto

    +mq(sp)
      max-width 345px
      display block

  .block_01
    .number
      fz--lg(2.5)

      +mq(sp)
        fz(1.4)
        text-align center

    .tel_btn
      text-align center
      margin-top px--lg(30)

      +mq(sp)
        margin-top 20px

      a
        line-height 1
        font-family skia_font
        fz--lg(3.7)
        color white_01
        transition all .3s

        +mq(sp)
          fz(2)

        &:hover
          opacity .7

    .tel_time
      fz--lg(2.3)
      text-align center
      margin-top px--lg(15)

      +mq(sp)
        fz(1.4)
  // --block_01

  .block_02
    margin-right 75px

    +mq(sp)
      margin-right 0
      margin-top 60px

    .logo
      line-height 1
      font-family skia_font
      fz--lg(5.1)
      text-align center

      +mq(sp)
        fz(2.4)

      a
        color white_01
        transition all .3s

        &:hover
          opacity .7

    .contact_btn
      width px--lg(300)
      border 2px solid white_01
      border-radius 10px
      margin px--lg(70) auto 0
      transition all .3s

      +mq(sp)
        width 140px
        margin-top 15px

      a
        line-height px--lg(86)
        font-family skia_font
        fz--lg(5.4)
        color white_01
        text-align center
        display block
        transition all .3s

        +mq(sp)
          line-height 40px
          fz(2)

      &:hover
        background-color white_01

        a
          color violet_01
  // --block_02

  .block_03
    +mq(sp)
      margin-top 60px

    .title
      line-height 1
      font-family skia_font
      fz--lg(3.7)
      text-align center

      +mq(sp)
        fz(1.8)

    .info
      margin-top px--lg(30)

      +mq(sp)
        margin-top 20px

      p
        fz--lg(2.5)

        +mq(sp)
          fz(1.4)
          text-align center

        &:nth-child(n + 2)
          margin-top px--lg(15)

        &:nth-child(3), &:nth-child(4)
          font-family skia_font
  // --block_03

  .footer-menu
    max-width 1190px
    margin px--lg(230) auto 0

    +mq(sp)
      margin-top 80px

    .navbar-nav > .menu-item > a
      color white_01
      margin-top 0

      &:hover
        color white_01 !important
        opacity .7

    .navbar-nav > .current-menu-item > a
      color violet_01 !important

      &:hover
        color violet_01 !important
  // --footer-menu

  .copy_right
    text-align center
    margin-top px--lg(230)

    +mq(sp)
      margin-top 60px

    small
      line-height 1
      fz--lg(1.3)

      +mq(sp)
        fz(1.2)
// --footer
