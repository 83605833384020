/**
 * Noto Sans JP
 */
font-face("Noto Sans JP", "NotoSansJP-Thin", 100)
font-face("Noto Sans JP", "NotoSansJP-Light", 300)
font-face("Noto Sans JP", "NotoSansJP-Regular", 400)
font-face("Noto Sans JP", "NotoSansJP-Medium", 500)
font-face("Noto Sans JP", "NotoSansJP-Bold", 700)
font-face("Noto Sans JP", "NotoSansJP-Black", 900)

/**
 * Skia
 */
font-face("Skia", "Skia", 400, "ttf", "TrueType")

/**
 * variables
 */
gothic_font = "Noto Sans JP", sans-serif
skia_font = "Skia", sans-serif
main_font = gothic_font

/**
 * styles
 */
html
  font-size 62.5% // 1rem = 10px

body
  line-height 1.7
  font-family main_font
  font-weight 400
  fz(1.6)
  color black_01

  +mq(sp)
    fz(1.4)

h1, h2, h3, h4, h5, h6
  font-weight 400

a
  color black_01
