#agent-header-outer
  display none

  +mq(sp)
    display block

    .navbar
      min-height 0
      padding 14px 0

    .navbar-header
      display flex
      justify-content space-between
      align-items center
      flex-direction row-reverse

      &::before, &::after
        display none

      .navbar-toggle
        margin 0 !important

      #logo_text h1#logo
        height auto !important

        a
          fz(1.6)

+mq(sp)
  #main-menu
    .menu-item
      a
        &:hover
          color black_01 !important

    .current-menu-item *
      color violet_01 !important
// --header
