/**
 * reset
 */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video
  margin 0
  padding 0
  border none
  outline none
  font-size 100%
  vertical-align baseline
  background transparent

body
  line-height 1

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
  display block

nav ul
  list-style none

blockquote, q
  quotes none

blockquote:before, blockquote:after, q:before, q:after
  content ""
  content none

a
  margin 0
  padding 0
  font-size 100%
  vertical-align baseline
  background transparent

ins
  background-color #ff9
  color #000
  text-decoration none

mark
  background-color #ff9
  color #000
  font-style italic
  font-weight bold

del
  text-decoration line-through

abbr[title], dfn[title]
  border-bottom 1px dotted

cursor: help, table
  border-collapse collapse
  border-spacing 0

hr
  display block
  height 1px
  border none
  border-top 1px solid #ccc
  margin 1em 0
  padding 0

input, select
  vertical-align middle

/**
 * my reset
 */
html
  box-sizing border-box

*, *::before, *::after
  box-sizing inherit

body
  width 100%
  -webkit-text-size-adjust 100%

header, main, footer
  width 100%
  max-width 1920px
  margin 0 auto

main
  display block // countermeasure against IE

h1, h2, h3, h4, h5, h6
  font-weight normal

ul
  list-style none

a
  text-decoration none

img
  width 100%
  height auto
  vertical-align top
