/**
 * font-size
 * ※Do not inherit fz()
 */
fz(fz)
  font-size unit(floor(fz, 1), "rem")
  font-size unit(floor(fz * 10, 1), "px")

fz--lg(fz)
  font-size unit(floor(fz * (1280 / 1920), 1), "rem")
  font-size unit(floor(fz * (1280 / 1920) * 10, 1), "px")

/**
 * font-face
 */
font-face(folder_name, font_name, font_weight, type = "otf", format = "OpenType")
  @font-face
    font-family folder_name
    font-weight font_weight
    src url("../../dst/fonts/" + folder_name + "/" + font_name + "." + type) format(format)
